.pdf-container {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
}

.pdf-container .react-pdf__Page {
  background-color: #1a1a1a !important;
  filter: invert(1);
}

.pdf-container .react-pdf__Page canvas {
  background-color: #1a1a1a !important;
}

/* This ensures text remains readable after inversion */
.pdf-container span,
.pdf-container p {
  color: #ffffff;
}
